// src/axios.js
import axios from 'axios';
import { getToken } from './keycloak';

// Crea un'istanza di Axios
const axiosI = axios.create({
    baseURL: process.env.VUE_APP_API_SERVER_BASEURL,
});

axiosI.interceptors.request.use(
    (config) => {
        const token = getToken();
        if (token) config.headers['Authorization'] = `Bearer ${token}`;
        else return Promise.reject(new Error('Token assente. Impossibile effettuare la richiesta.'))
        return config;
    },
    (error) => Promise.reject(error)
);

const cache = {};
const CACHE_DURATION = 5 * 60 * 1000;

function generateCacheKey(url, params) {
    const sortedParams = new URLSearchParams(params).toString();
    return `${url}?${sortedParams}`;
}

function fetchWithCache(url, parameters, onlyCached) {
    const cacheKey = generateCacheKey(url, parameters);
    const cachedItem = cache[cacheKey];

    if (onlyCached || (cachedItem && (Date.now() - cachedItem.timestamp < CACHE_DURATION))) {
        console.log('Servito dalla cache:', url);
        return Promise.resolve(cachedItem != null ? cachedItem.data : null);
    }

    return axiosI.get(url, { params: parameters })
        .then(response => {
            cache[cacheKey] = {
                data: response.data,
                timestamp: Date.now()
            };
            return response.data;
        });
}

export { axiosI, fetchWithCache };