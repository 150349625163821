<template>
  <v-app>
    <v-container>
      <h1 class="text-center">Question Managing System</h1>

      <v-row>
        <v-btn v-if="!isAuthenticatedRef" color="primary" @click="login">Login</v-btn>
        <v-btn v-if="isAuthenticatedRef" color="secondary" @click="logout">Logout</v-btn>
        <!--<v-btn @click="toggleDarkTheme">Toggle Theme</v-btn>-->
      </v-row>

      <v-tabs v-model="tab" class="mb-4 mt-2">
        <v-tab class="d-none">Inserisci Domanda</v-tab>
        <v-tab>Ricerca Domanda</v-tab>
      </v-tabs>

      <v-tab-item v-if="tab === 0 && FALSE">
        <v-card>
          <v-card-title>Aggiungi una Domanda</v-card-title>
          <v-card-text>
            <v-row class="mb-3 mt-2">
              <v-col cols="12" md="6" class="pb-0 pt-0">
                <!-- Occupano tutta la larghezza su mobile e metà su desktop -->
                <v-select v-model="selectedCourse" :items="courseNames" label="Seleziona corso"
                  :loading="loadingCourses" :disabled="loadingCourses || coursesData.length === 0"></v-select>
              </v-col>
              <v-col cols="12" md="6" class="pt-0 pb-0">
                <v-select v-model="selectedTeaching" :items="filteredTeachings" label="Seleziona insegnamento"
                  :disabled="!selectedCourse || loadingCourses"></v-select>
              </v-col>
            </v-row>
            <v-textarea v-model="question" label="Domanda" hint="Inserisci la domanda qui" class="mb-3"></v-textarea>
            <v-textarea v-model="answer" label="Risposta" class="mb-3"></v-textarea>
            <v-btn color="primary" @click="addQuestion">Aggiungi Domanda</v-btn>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item v-if="tab === 1">
        <v-card>
          <v-card-title>Cerca Domanda</v-card-title>
          <v-card-text>
            <v-row class="mb-3 mt-2">
              <v-col cols="12" md="5" class="pb-0 pt-0">
                <!-- Occupano tutta la larghezza su mobile e metà su desktop -->
                <v-select v-model="selectedCourse" :items="courseNames" label="Seleziona corso"
                  :loading="loadingCourses" :disabled="loadingCourses || coursesData.length === 0"></v-select>
              </v-col>
              <v-col cols="12" md="5" class="pb-0 pt-0">
                <v-select v-model="selectedTeaching" :items="filteredTeachings" label="Seleziona insegnamento"
                  :disabled="!selectedCourse || loadingCourses"></v-select>
              </v-col>
              <v-col cols="12" md="2" align="center" justify="center">
                <v-chip color="primary" label>
                  Domande: <b>{{ domandeTotali }}</b>
                </v-chip>
              </v-col>
            </v-row>
            <v-text-field v-model="searchQuery" label="Cerca Domanda" @input="searchQuestions" class="mb-0"
              :disabled="selectedTeaching === ''"></v-text-field>
            <v-text-field v-model="searchSubQuery" label="Filtra risultati" @input="searchSubQuestions"
              class="mb-3 mt-0" :disabled="searchQuery.trim().length <= 2"></v-text-field>

            <v-list class="scrollable-list">
              <v-list-item-group>
                <v-list-item v-for="question in searchResults" :key="question.id" class="question-item">
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold display-content multiline v-list-item--multiline"
                      v-html="question.domanda" />
                    <v-list-item-subtitle class="text-right mt-1 multiline v-list-item--multiline"
                      v-html="question.risposta" />
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="searchResults.length > 0"></v-divider>
                <v-list-item v-if="searchResults.length === 0">
                  <v-list-item-content>
                    <v-list-item-title>No results found.</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-container>

    <!-- Footer -->
    <v-footer app padless>
      <v-col class="text-center" cols="12">
        <v-divider></v-divider>
        <p class="footer-text">© 2024 Question Managing System - Version {{ appVersion }}</p>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import { ref, watch, computed, onMounted } from 'vue';
import { useTheme } from 'vuetify';
import { axiosI, fetchWithCache } from './axios';
import { isAuthenticated, login, logout } from './keycloak';

const QUERY_DELAY = 1 * 1000;

export default {
  /*methods: {
    checkAdblock() {
      const ADS_URL = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'

      function checkAdsBlocked(callback) {
        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
          if (xhr.readyState == XMLHttpRequest.DONE) callback(xhr.status === 0 || xhr.responseURL !== ADS_URL)
        };
        xhr.open('HEAD', ADS_URL, true);
        xhr.send(null);
      }

      checkAdsBlocked((adsBlocked) => {
        if (adsBlocked) this.showAdblockMessage()
      });
    },

    showAdblockMessage() {
      document.body.innerHTML = '';

      const message = document.createElement('div');
      message.classList.add('adblock-banner')
      message.textContent = 'Per favore, disattiva Adblock per continuare a navigare su questo sito.';
      document.body.appendChild(message);
    }
  },
  mounted() {
    this.checkAdblock()
  },*/
  setup() {
    const appVersion = '2.2.5';
    const forceUpdate = ref(0);
    const tab = ref(1);
    const coursesData = ref(['']);
    const selectedCourse = ref('');
    const selectedTeaching = ref('');
    const question = ref('');
    const answer = ref('');
    const searchQuery = ref('');
    const searchSubQuery = ref('');
    const searchResults = ref([]);
    const domandeTotali = ref('0');
    const loadingCourses = ref(false);
    let lastQuerySearch = '';
    let lastQuerySearchTime = null;
    const isAuthenticatedRef = ref(isAuthenticated());
    const theme = useTheme();

    // Funzione per alternare il tema
    const toggleDarkTheme = () => {
      theme.global.name.value = theme.global.name.value === 'light' ? 'dark' : 'light'
    };

    // Rendi reattiva la variabile di autenticazione
    const checkAuth = () => {
      isAuthenticatedRef.value = isAuthenticated();
    };

    // Aggiungi un watcher per controllare l'autenticazione
    watch(isAuthenticatedRef, () => {
      if (!isAuthenticatedRef.value) checkAuth()
    });

    const getCoursesData = async () => {
      loadingCourses.value = true
      axiosI.get('/course')
        .then(response => {
          coursesData.value = response.data.courses
          loadingCourses.value = false
        })
        .catch(() => {
          loadingCourses.value = false
        });
    };

    const courseNames = computed(() => coursesData.value.map((courseObj) => courseObj.course));

    const filteredTeachings = computed(() => {
      forceUpdate;
      if (!selectedCourse.value) return []
      const selectedCourseObj = coursesData.value.find(courseObj => courseObj.course === selectedCourse.value)
      return selectedCourseObj ? selectedCourseObj.teachings : []
    });

    const addQuestion = () => {
      axiosI.post('/question', {
        corso: selectedCourse.value,
        insegnamento: selectedTeaching.value,
        domanda: question.value,
        risposta: answer.value.trim(),
      }).then(response => {
        alert(response.data.message);
        question.value = '';
        answer.value = '';
      }).catch(error => {
        console.error(error);
        alert('Errore durante l\'aggiunta della domanda.');
      });
    };

    const searchQuestions = () => {
      searchSubQuery.value = ''
      searchSubQuestions()
    };

    const searchSubQuestions = () => {
      let onlyCached = true
      if (lastQuerySearch !== `${searchQuery.value}_${searchSubQuery.value}`.replaceAll(' ', '')
        && (Date.now() - lastQuerySearchTime) > QUERY_DELAY) {
        lastQuerySearchTime = Date.now()
        lastQuerySearch = `${searchQuery.value}_${searchSubQuery.value}`.replaceAll(' ', '')
        onlyCached = false
      }
      fetchWithCache('/question',
        {
          corso: selectedCourse.value,
          insegnamento: selectedTeaching.value,
          query: searchQuery.value,
          subQuery: searchSubQuery.value
        }, onlyCached)
        .then(response => {
          if (response != null) searchResults.value = response
        })
        .catch(console.error);
    };

    const getTotal = () => fetchWithCache('/question/count', {
      corso: selectedCourse.value,
      insegnamento: selectedTeaching.value
    }).then(response => domandeTotali.value = response.total)
      .catch(console.error);

    watch(selectedTeaching, (newTeaching) => {
      if (newTeaching) {
        searchResults.value = [];
        getTotal();
      }
    });

    watch(selectedCourse, (newCourse, oldCourse) => {
      if (newCourse) {
        searchResults.value = [];
        if (newCourse !== oldCourse) {
          forceUpdate.value += 1;
          selectedTeaching.value = '';
        }
        getTotal();
      }
    });

    onMounted(() => {
      if (isAuthenticatedRef.value) getCoursesData()
    });

    return {
      appVersion,
      tab,
      coursesData,
      selectedCourse,
      selectedTeaching,
      question,
      answer,
      searchQuery,
      searchSubQuery,
      searchResults,
      addQuestion,
      searchQuestions,
      searchSubQuestions,
      domandeTotali,
      loadingCourses,
      courseNames,
      filteredTeachings,
      isAuthenticatedRef,
      login,
      logout,
      toggleDarkTheme,
    };
  },
};
</script>

<style scoped>
.v-card {
  margin: 20px 0;
  background-color: #ffffff;
  /* Colore di sfondo della scheda */
  color: #333;
  /* Colore del testo */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  /* Leggera ombra */
}

.question-item {
  padding: 15px;
  background-color: #f5f5f5;
  /* Colore di sfondo per le domande */
  border-radius: 4px;
  margin-bottom: 10px;
  transition: background-color 0.3s;
  /* Transizione per il colore di sfondo */
}

.question-item:hover {
  background-color: #ebebeb;
  /* Colore al passaggio del mouse */
}

.font-weight-bold {
  font-weight: 600;
}

.text-right {
  text-align: right;
  /* Allinea il testo a destra */
}

.v-divider {
  margin: 10px 0;
}

body {
  background-color: #f0f0f0;
  /* Colore di sfondo generale */
}

.scrollable-list {
  max-height: calc(80vh - 200px);
  overflow-y: auto;
}

.v-list-item {
  padding: 10px;
}

.footer-text {
  font-size: 0.8rem;
  /* Dimensione del font desiderata */
}

.display-content {
  display: contents;
}

.display-flex {
  display: flex;
}

.multiline {
  white-space: normal;
}
</style>
