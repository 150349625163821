import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { initKeycloak } from './keycloak';

loadFonts()

const app = createApp(App).use(vuetify)

initKeycloak().then(() => {
  app.mount('#app');
}).catch(err => {
  console.error('Keycloak initialization failed:', err);
});